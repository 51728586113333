<template>
  <attendEmployee
    :isLoad="isLoad"
    :messageApi="messageApi"
    @downLoadExcel="fetchData"
    name="حضور الموظفين"
  />
</template>

<script>
import attendEmployee from "./report.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoad: false,
      messageApi: "",
    };
  },
  components: {
    attendEmployee,
  },
  computed: {
    ...mapGetters("dataList", ["message", "success_api"]),
  },
  methods: {
    ...mapActions("dataList", ["excelAttendEmployee"]),
    async fetchData(value) {
      this.isLoad = true;
      await this.excelAttendEmployee(value);
      this.messageApi = this.message;
      this.isLoad = false;
    },
  },
};
</script>
